<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 44.51 66.79"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M3.48,32.58,3,33.41l-.23.43s0,0,0,0A22.24,22.24,0,1,0,44.51,44.53a22.44,22.44,0,0,0-2.75-10.65l0,0-.23-.43L41,32.58a3.62,3.62,0,0,0-.22-.32c-.4-.6-.91-1.35-1.54-2.15L38,28.5c-1.49-1.84-3.35-4.14-3.84-5.43a23.46,23.46,0,0,1-1.39-5.39l-.19-2.49H12l-.21,2.47a23.34,23.34,0,0,1-1.39,5.4C9.87,24.36,8,26.66,6.52,28.5L5.24,30.11c-.63.8-1.14,1.55-1.54,2.15A3.62,3.62,0,0,0,3.48,32.58Zm7.25-.67c1.91-2.37,3.89-4.81,4.69-6.91a29.64,29.64,0,0,0,1.31-4.4H27.78A29.64,29.64,0,0,0,29.09,25c.8,2.1,2.78,4.54,4.68,6.9L35,33.44a21.65,21.65,0,0,1,1.76,2.61A16.83,16.83,0,1,1,5.41,44.53a17.11,17.11,0,0,1,2.32-8.48A24.5,24.5,0,0,1,9.5,33.44Z"
        /><path class="cls-1"
                d="M25,12.11h7.81V8.46a6.84,6.84,0,0,0-1.29-4.09,6,6,0,0,0-4-1.78A3.17,3.17,0,0,0,26.87.92,3.34,3.34,0,0,0,24.4,0H20.11a3.34,3.34,0,0,0-2.47.92A3.17,3.17,0,0,0,17,2.59a6,6,0,0,0-4,1.78,6.86,6.86,0,0,0-1.29,4.11v3.63H25Z"
        />
      </g>
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #292828;
}
</style>
